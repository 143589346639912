import React from "react";
import { inject, observer } from "mobx-react";
import MapViewComponent from "./MapPointsData";

@inject("device")
@observer
class MapLayout extends React.Component {
  render() {
    const { device, forwardRef, ...props } = this.props;
    return <MapViewComponent {...props} ref={forwardRef} deviceList={device.cameraList} />;
  }
}

export default MapLayout
