import React from "react";
import { Input, Select } from "antd";

const IconFont = Loader.loadBaseComponent("IconFont");
const Option = Select.Option;
const { cameraAndSoldierType, deviceStatus } = Dict.map;

export default class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.suffix = Math.random();
    this.state = {
      inputKey:Math.random()
    }
  }

  timer = null;
  componentWillUnmount() {
    clearTimeout(this.timer);
    this.timer = null;
  }
  changeStatus = status => {
    this.props.changeForm({ status });
  };
  changeType = type => {
    this.props.changeForm({ type });
  };
  changeKeyword = event => {
    clearTimeout(this.timer);
    let keyword = event.target.value;
    this.timer = setTimeout(() => {
      this.props.changeForm && this.props.changeForm({ keyword });
    }, 500);
  };
  clearKeyword = () => {
    this.setState({ inputKey: Math.random() });
    clearTimeout(this.timer);
    this.props.changeForm && this.props.changeForm({ keyword: null });
  };
  render() {
    const { keyword, status, type } = this.props.params;
    return (
      <div className="form-search">
        <div className="search-keyword">
          <Input
            prefix={<IconFont type="icon-M_Bar_Magnifier" />}
            suffix={keyword && <IconFont type="icon-S_Photo_MarkNo" onClick={this.clearKeyword} style={{ fontSize: 12, cursor: "pointer" }} />}
            placeholder="请输入摄像机名称/SN/箱体号搜索"
            defaultValue={keyword}
            key={this.state.inputKey}
            onChange={this.changeKeyword}
          />
        </div>
        <div className={`search-type search-type-${this.suffix}`}>
          <Select
            defaultValue={status}
            getPopupContainer={() => document.getElementsByClassName(`search-type-${this.suffix}`)[0]}
            allowClear={true}
            placeholder={"摄像机状态"}
            onChange={this.changeStatus}
            suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}
          >
            {deviceStatus.map(item => (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Select
            defaultValue={type}
            getPopupContainer={() => document.getElementsByClassName(`search-type-${this.suffix}`)[0]}
            allowClear={true}
            placeholder={"摄像机类型"}
            onChange={this.changeType}
            suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}
          >
            {cameraAndSoldierType.map(item => (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}
