import React from 'react'
import { videoContext } from "../../../moduleContext";
import { Button } from "antd";

const IconFont = Loader.loadBaseComponent('IconFont')

@videoContext
class GroupAction extends React.Component{
  render(){
    const {collectionList,showGroupModal} = this.props
    return <div className="group-action">
    <div className="group-num-info">
      <div>
        <span>分组数量：</span>
        {collectionList.length}
      </div>
      <div>
        <span>摄像机数量：</span>
        {BusinessStore.deviceGroup.groupCountDevice}
      </div>
    </div>
    <Button onClick={showGroupModal} type="primary" className="orange-btn">
      <IconFont type="icon-S_Edit_PlusLine" style={{ fontSize: 14 }} />
      新增分组
    </Button>
  </div>
  }
}

export default GroupAction