import React from "react";
import { Button, message } from "antd";
import { withRouter } from "react-router-dom";
import ListView from "./view/list";
import _ from "lodash";
import moment from 'moment'

import ResourceTreeView from "./components/ResouceTree";
import VideoLoopSetting from "./components/VideoLoopSetting";
import GroupModal from "./components/GroupModal";
import { Provider } from "./moduleContext";
import MoveableTimeChoise from "./components/MoveableTimeChoise";
import MapLayout from "./components/MapLayout";

import "./style/index.less";

const videoScreen = Dict.getDict("videoScreen");
const IconFont = Loader.loadBaseComponent("IconFont");

@withRouter
@Decorator.businessObserver({})
class VideoSurveillance extends React.Component {
  constructor(props) {
    super(props);
    this.clsSuffix = Math.random();
    this.videoLayoutRef = React.createRef();
    this.mapViewRef = React.createRef();
    this.listViewRef = React.createRef();
    this.timeChoiseRef = React.createRef();
    this.playerDatas = []; //当前播放的视频列表

    //TODO 轮巡相关
    this.loopList = []; //轮巡设备列表
    this.loopInterval = 1000; //轮巡间隔时间
    this.loopVideoBox = null; //可轮巡的播放容器配置
    this.loopListNumber = 1; //轮巡设备的页数
    this.loopTimer = null; //轮巡定时器
    this.loopOneListSize = 0; //每次轮巡的数量

    const { location } = this.props;
    const urlParams = Utils.queryFormat(location.search);
    const mapMode = !(urlParams.mapMode === "false");
    this.state = {
      mapMode,
      isSlide: true,
      selectDevice: [],
      isLoop: false,
      showLoopSetting: false,
      loopOrgInfo: {},
      loopGroupName: null,
      showGroup: false,
      currentGroup: null,
      groupModalKey: Math.random(),
      loopModalKey: Math.random()
    };
  }
  /**
   * @desc context 属性
   */
  getModuleContext() {
    return {
      isMapMode: this.state.mapMode, //是否地图模式
      selectDevice: this.state.selectDevice, //选中的设备集合
      onSelectDevice: this.onSelectDevice, //地图模式选中设备后执行的逻辑
      setDeviceListForCurrentPlayerBox: this.setDeviceListForCurrentPlayerBox, //对比播放中的设备，匹配当前选中设备列表，去除无效的设备选中状态
      startVideoLoop: this.startVideoLoop, //
      endVideoLoop: this.endVideoLoop, //
      showLoopSettingLayout: this.showLoopSettingLayout, //
      closeLoopSettingLayout: this.closeLoopSettingLayout, //
      playBoxConfig: [], //对于轮巡的窗口配置
      isLoop: this.state.isLoop, //轮巡的状态
      loopOrgInfo: this.state.loopOrgInfo,
      loopGroupName: this.state.loopGroupName,
      deleteGroupDevice: this.deleteGroupDevice,
      showGroupModal: this.showGroupModal,
      goPage: this.goPage,
      deleteGroup: this.deleteGroup,
      getVideoLayoutDom: this.getVideoLayoutDom,
      handleHistoryVideo: this.handleHistoryVideo, // 处理历史视频时间选择
      toggleTimeChoiseContent: this.toggleTimeChoiseContent, // 时间控件开关
      getHistoryTimeRange: this.getHistoryTimeRange, // 获取历史视频时间
      queryRealTimeAddressMulti: this.queryRealTimeAddressMulti, // 获取实时视频流
      queryHistoryAddress: this.queryHistoryAddress, // 获取历史视频流
    };
  }

  componentWillMount() {
    this.getCacheZoomCenter();
  }

  componentDidMount() {
    const { deviceGroup, location } = this.props;
    deviceGroup.initGroupData();
    this.getDevicePrefercence().then(() => {
      const urlParams = Utils.queryFormat(location.search);
      if (!urlParams.pid) {
        return;
      }
      LM_DB.get("parameter", urlParams.pid).then(result => {
        if (!result) {
          return;
        }
        const { selectIds = [] } = result.data;
        let currentVideoScreen = videoScreen[1];
        if (selectIds.length > 4) {
          currentVideoScreen = videoScreen[2];
        }
        if (selectIds.length > 9) {
          currentVideoScreen = videoScreen[3];
        }
        if (currentVideoScreen !== videoScreen[1]) {
          this.selectSrceen(currentVideoScreen);
        }
        const list = BaseStore.device.getListByIdsOrCids(selectIds);
        if (list.length > 0) {
          this.setState({ selectDevice: list }, () => {
            const listViewRef = this.listViewRef.current;
            listViewRef && listViewRef.selectDevice(list);
          });
        }
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.loopTime);
    this.cacheZoomAndCenter();
    setTimeout(() => {
      this.mapViewRef = null;
      this.listViewRef = null;
      this.playerDatas = null;
      this.loopList = null;
      this.loopInterval = null;
      this.loopVideoBox = null;
      this.loopListNumber = null;
      this.loopTimer = null;
      this.loopOneListSize = null;
      this.timeChoiseRef = null;
    }, 60);
  }

  getCacheZoomCenter = () => {
    const mapConfig = Utils.getUserCache("videoMapConfig");
    this.mapConfig = mapConfig || {};
  };

  cacheZoomAndCenter = () => {
    if (this.state.mapMode) {
      const map = this.mapViewRef.current.wrappedInstance.map;
      const center = map.getCenter();
      const zoom = map.getZoom();
      const storeValue = { center: [center.lng, center.lat], zoom };
      Utils.setUserCache("videoMapConfig", storeValue);
      this.mapConfig = {};
    }
  };

  /**
   * @desc 切换模式清空已选中的设备
   */
  changeModeBtn = () => {
    const { mapMode, isLoop } = this.state;
    Utils.exitFullscreen();
    if (isLoop) {
      this.endVideoLoop();
    }
    this.playerDatas = [];
    const clearTime = true;
    this.toggleTimeChoiseContent(false, clearTime);
    if (mapMode) {
      this.cacheZoomAndCenter();
    } else {
      this.getCacheZoomCenter();
    }
    this.setState(
      {
        mapMode: !mapMode,
        selectDevice: []
      },
      () => {
        this.goPage({
          moduleName: "videoSurveillance",
          isUpdate: true,
          action: "replace",
          data: { mapMode: !mapMode }
        });
      }
    );
  };

  /**
   * 隐藏左侧树形控件
   */
  slideAction = () => {
    const { isSlide } = this.state;
    this.setState({ isSlide: !isSlide });
  };

  /**
   * @desc 显示轮巡的配置Modal 获取轮巡的摄像机列表
   * @param {object} item 组织信息
   * @param {boolean} isGroup 是否从分组过来
   */
  showLoopSettingLayout = (item, isGroup, type) => {
    const { isLoop } = this.state;
    if (isLoop) {
      return message.warn("当前正在执行轮巡任务！");
    }
    if (isGroup) {
      this.loopList = item.deviceList;
      this.setState({
        showLoopSetting: true,
        loopGroupName: item.groupName,
        loopModalKey: Math.random()
      });
    } else {
      if (type === "org") {
        this.loopList = BaseStore.device.queryCameraByIncludeOrgId(item.id);
      } else {
        this.loopList = BaseStore.device.queryCameraByIncludePlaceId(item.id);
      }
      this.setState({
        showLoopSetting: true,
        loopOrgInfo: item,
        loopModalKey: Math.random()
      });
    }
  };

  /**
   * @desc 关闭轮巡配置窗口
   */
  closeLoopSettingLayout = () => {
    this.setState(
      {
        showLoopSetting: false,
        loopOrgInfo: {},
        loopGroupName: null
      },
      () => {
        setTimeout(() => this.setState({ loopModalKey: Math.random() }), 500);
      }
    );
  };

  /**
   * @desc 开始轮巡
   */
  startVideoLoop = ({ loopInterval, loopScreen, loopVideoBox }) => {
    this.setState({ showLoopSetting: false });
    this.selectSrceen(loopScreen);
    this.loopInterval = loopInterval;
    this.loopVideoBox = loopVideoBox;
    this.loopListNumber = 1;
    this.loopOneListSize = this.loopVideoBox.filter(v => v.isLoop).length;
    this.listViewRef.current.setLoopVideBox(this.loopVideoBox);
    this.setState({ isLoop: true });
    this.setCurrentLoopList();
    this.loopTimer = setInterval(() => {
      this.loopListNumber++;
      Array.isArray(this.loopList) && this.setCurrentLoopList();
    }, this.loopInterval + 1000);
  };

  /**
   * @desc 设置当前需要轮巡的设备
   */
  setCurrentLoopList() {
    const { selectDevice } = this.state;
    let startIndex = (this.loopListNumber - 1) * this.loopOneListSize;
    if (this.loopList.length <= this.loopOneListSize) {
      this.loopListNumber = 0;
    } else {
      if (startIndex + this.loopOneListSize >= this.loopList.length) {
        startIndex = this.loopList.length - this.loopOneListSize;
        this.loopListNumber = 0;
      }
    }
    let list = this.loopList.slice(startIndex, startIndex + this.loopOneListSize);
    this.listViewRef.current.playMethodForLoopDevice(list);
    this.setState({ selectDevice: selectDevice.concat(list) });
  }

  /**
   * @desc 结束轮巡
   */
  endVideoLoop = () => {
    this.loopList = [];
    this.loopListNumber = 1;
    this.loopVideoBox.forEach(item => {
      item.isLoop = false;
    });
    this.listViewRef.current.setLoopVideBox(this.loopVideoBox);
    clearInterval(this.loopTimer);
    this.setState({
      isLoop: false,
      loopOrgInfo: {},
      loopGroupName: null
    });
    message.success("结束轮巡！");
  };

  /**
   * @desc 摄像机列表选中设备后，执行的逻辑
   * @param {Object<CameraDevice>} item
   */
  onSelectDevice = (item, options = { isLiving: true }) => {
    const hasLiving = !!BaseStore.menu.getInfoByName("realVideo");
    if (!hasLiving && (options.isLiving === undefined || options.isLiving)) {
      // console.log('无直播权限');
      return;
    }
    const { mapMode } = this.state;
    if (mapMode) {
      // 地图模式只能选中一个设备
      this.setState({ selectDevice: [item] });
      this.mapViewRef.current.wrappedInstance.markerClick(item, options);
    } else {
      // 列表模式可选中多个设备
      // 当前设备已经在播放实时视频，不做任何操作
      const livePlaying = this.playerDatas.find(v => v.cid === item.cid && v.isLiving === true);
      if (livePlaying) {
        return message.info("当前设备实时视频已打开");
      }
      const { selectDevice } = this.state;
      selectDevice.push(item);
      this.setState({ selectDevice });
      this.listViewRef.current.selectDevice([item]);
    }
  };

  /**
   * @desc 方法成功后，play容器返回，播放信息集合对比选中设备，删除无效选中的设备
   * @param {Object<CameraDevice & file | historyList>} playerDatas
   */
  setDeviceListForCurrentPlayerBox = playerDatas => {
    const { selectDevice } = this.state;
    this.playerDatas = playerDatas;
    const hasHistory = this.playerDatas.find(v => !v.isLiving && v.cid);
    if (!hasHistory) {
      const clearTime = true;
      this.toggleTimeChoiseContent(false, clearTime);
    }
    //TODO 子组件反馈播放容器数据后，核对选中的设备
    let list = selectDevice
      .map(item => {
        const isHas = this.playerDatas.filter(v => !!v).findIndex(v => v.id === item.cid || v.id === item.id) > -1;
        return isHas ? item : null;
      })
      .filter(v => !!v);
    this.setState({ selectDevice: list, loopModalKey: Math.random() });
  };

  /**
   * @desc 切换屏幕数量
   * @param {Object<>} item
   */
  selectSrceen = (item, fileDatas) => {
    const { videoModule } = this.props;
    if(fileDatas) {
      this.playerDatas = fileDatas;
      const selectDevice = this.playerDatas.filter(v => v.id);
      this.setState({
        selectDevice
      }) 
    }
    videoModule.setVideoScreen(item);
  };

  /**
   * @desc 关闭按钮后，清空选中的设备
   */
  clearSelectDevice = () => {
    this.playerDatas = [];
    this.toggleTimeChoiseContent(false);
    this.setState({ selectDevice: [], loopModalKey: Math.random() });
  };

  /**
   * @desc 列表模式关闭一个窗口(检测时间控件是否需要更新)
   */
  closeSingleVideo = fileData => {
    const { deviceInfo } = this.timeChoiseRef.current.state;
    if (!fileData.isLiving && fileData.cid === deviceInfo.cid) {
      const nextHistory = this.playerDatas.find(v => !v.isLiving && v.cid);
      if (nextHistory) {
        this.updateTimeChoiseDeviceInfo(nextHistory);
      }
    }
  };

  /**
   * @desc 提供子组件新开页签的方法
   * @param {Object} options
   */
  goPage = options => {
    const { location } = this.props;
    const freeTabIndex = BaseStore.tab.getFreeTabIndex();
    if (!freeTabIndex) {
      // 页签开到最大值时，关闭当前页签，新开一个页签
      BaseStore.tab.closeCurrentTab({
        location
      });
    }
    BaseStore.tab.goPage({
      location,
      ...options
    });
  };

  /**
   * @desc 删除收藏下的设备
   * @param {Object} item
   */
  deleteGroupDevice = item => {
    const { deviceGroup } = this.props;
    return deviceGroup.deleteGroupDevice(item);
  };

  /**
   * @desc 新增收藏下的设备
   * @param {Object} item
   */
  editGroupDevice = (items, isEmpty) => {
    const { deviceGroup } = this.props;
    return deviceGroup.editDevice(items, isEmpty);
  };

  /**
   * @desc 打开分组弹窗
   * @param {boolean} isEdit
   * @param {Object} group
   */
  showGroupModal = (isEdit, group) => {
    this.setState({
      showGroup: true,
      currentGroup: isEdit ? group : null,
      groupModalKey: Math.random()
    });
  };

  /**
   * @desc 关闭分组弹窗
   */
  hideGroupModal = () => {
    this.setState({ showGroup: false });
  };

  /**
   * @desc 新增分组
   */
  addOrEditGroup = (isEdit, name, list, group) => {
    const { deviceGroup } = this.props;
    let deviceIds = list.map(v => `${v.cid}/${v.deviceName}`);
    if (isEdit) {
      return deviceGroup.editGroup({ groupName: group.groupName }, { groupName: name, deviceIds }).then(() => {
        this.hideGroupModal();
        message.success("操作成功！");
      });
    } else {
      return deviceGroup
        .add({
          groupName: name,
          deviceIds
        })
        .then(() => {
          this.hideGroupModal();
          message.success("操作成功！");
        });
    }
  };

  cancelAddGroup = () => {
    this.setState({ showGroup: false });
  };

  /**
   * @desc 删除收藏下的设备
   * @param {String} name
   */
  deleteGroup = name => {
    const { deviceGroup } = this.props;
    return deviceGroup.delete({ groupName: name });
  };

  // 历史视频业务
  /**
   * @desc 处理历史视频点击事件
   * @param {object} item: 设备信息
   */
  handleHistoryVideo = async item => {
    let deviceStorageLimit;
    if(item.isFrontEnd) {
      deviceStorageLimit = await this.queryFrontEndDeviceInfoByCid(item);
      if(!deviceStorageLimit.startTime) {
        // 如果没有前端录像的情况下，直接return
        return message.info((deviceStorageLimit.data && deviceStorageLimit.data.message) || '暂无前端录像')
      }
    } else {
      if(!item.storageLimit) {
        deviceStorageLimit = await this.getDeviceStorageLimit(item);
      }
    }
    if(deviceStorageLimit) {
      item.storageLimit = deviceStorageLimit.storageLimit;
      item.storageLimitInfo = deviceStorageLimit
    }
    this.updateTimeChoiseDeviceInfo(item);
    this.toggleTimeChoiseContent();
  };

  // 查询设备存储周期
  getDeviceStorageLimit = item => {
    return Service.device
      .queryDeviceInfoByCid(item.cid)
      .then(result => {
        let storageLimit;
        try {
          storageLimit = +result.data.extJson.cameraInfo.storage.video || 7;
        } catch {
          storageLimit = 7;
        }
        return { storageLimit };
      })
      .catch(e => ({ storageLimit: 7 }));
  };

  // 获取前端录像时间轴
  queryFrontEndDeviceInfoByCid = (item) => {
    let frontEndInfo = {
      dataList: [],
      endTime: true,
      startTime: false,
      // startTime: -2,
    }
    return Service.video.localRecordTimeLine({cid: item.cid}).then(result => {
      let dataList = result.data || []
      if(dataList.length){
        frontEndInfo = {
          dataList,
          startTime: dataList[0].from * 1000,
          endTime: dataList[dataList.length - 1].to * 1000,
        }
      }
      return frontEndInfo
    }).catch(err => {
      return frontEndInfo
    })
  }

  // 更新设备
  updateTimeChoiseDeviceInfo = item => {
    this.timeChoiseRef.current.setDeviceInfo(_.cloneDeep(item));
  };
  /**
   * @desc 提交选时控件时间
   * @param {object} options: { startTime, endTime }
   */
  submitHistoryTime = options => {
    const MAX_HISTORY_GAP = 7;
    if (options.startTime - options.endTime > 3600 * 24 * MAX_HISTORY_GAP) {
      return message.error(`录像视频查看不能超过${MAX_HISTORY_GAP}天`);
    }
    options.isLiving = false;
    const { mapMode, selectDevice } = this.state;
    const { deviceInfo } = this.timeChoiseRef.current.state;
    if (mapMode) {
      this.onSelectDevice(deviceInfo, options);
    } else {
      const playerIndex = this.playerDatas.findIndex(v => v.cid === deviceInfo.cid && v.isLiving === false);
      selectDevice.push(deviceInfo);
      this.setState({ selectDevice });
      this.listViewRef.current.playerHistoryVideo(options, playerIndex, deviceInfo);
    }
  };
  // 获取选时控件时间
  getHistoryTimeRange = () => {
    const { startTime, endTime } = this.timeChoiseRef.current.state;
    const timeRange = {
      startTime,
      endTime
    };
    return timeRange;
  };
  /**
   * @desc 选时控件显示或隐藏开关
   * @params {boolean} visible 显示/隐藏
   * @params {boolean} clearTime 清空时间
   */
  toggleTimeChoiseContent = (visible = true, clearTime = false) => {
    if (!visible) {
      this.timeChoiseRef.current.setDeviceInfo();
    }
    this.timeChoiseRef.current.setVisible(visible, clearTime);
  };

  /**
   * @desc 获取历史视频流
   */
  queryHistoryAddress = ({ cid, startTime, endTime, deviceName }) => {
    const { deviceInfo } = this.timeChoiseRef.current.state;
    const data = {
      cid,
      deviceName,
      startTime,
      endTime
    };
    if(deviceInfo.isFrontEnd) {
      return Service.video.localRecordControl(data) 
    } else {
      return Service.video.queryHistoryAddress(data)
    }
  };

  /**
   * @desc 获取实时视频流
   */
  queryRealTimeAddressMulti = deviceList => {
    const deviceInfos = deviceList.map(v => {
      let flvStream = BaseStore.device.streamPreference.indexOf(v.cid) !== -1;
      if(!flvStream && !v.hasStorage){
        flvStream = true
      }
      const data = {
        cid: v.cid || v.id,
        deviceName: v.deviceName || v.name,
        deviceType: v.deviceType,
        flvStream
      };
      // ptzTypes.push(
      //   info.extJson &&
      //     info.extJson.cameraInfo &&
      //     info.extJson.cameraInfo.type
      // );
      return data;
    });
    return Service.video.queryRealTimeAddressMulti(deviceInfos);
  };

  // 获取设备开流偏好设置
  getDevicePrefercence = () => {
    const userId = BaseStore.user.userInfo.id;
    const storeKey = BaseStore.device.streamKVKey;
    return Service.kvStore
      .getKvStore({ userId, storeKey })
      .then(storeValue => {
        const streamPreference = JSON.parse(storeValue.data.storeValue || "[]");
        return BaseStore.device.setStreamPreference(streamPreference);
      })
      .catch(() => Promise.resolve());
  };

  getLinkSelectList = () => {
    const selectList = [this.hoverMarkerPoint];
    return selectList;
  };

  markerMouseover = point => {
    this.hoverMarkerPoint = point;
  };

  getVideoLayoutDom = () => {
    if(!this.videoLayoutDom) {
      this.videoLayoutDom = document.getElementsByClassName('video-surveillance' + this.clsSuffix)[0];
    }
    return this.videoLayoutDom
  }

  // 前端录像seek事件
  onFrontEndSeek = (seekTime, fileData, percent) => {
    const isFrontEndSeek = true;
    const { mapMode } = this.state;
    const { beginDate, duration } = fileData.historyList;
    const startTime = moment(seekTime).format('X');
    const endTime = +moment(beginDate).format('X') + duration;
    const options = { startTime, endTime, isFrontEndSeek, percent, isLiving: false };
    if (mapMode) {
      this.mapViewRef.current.wrappedInstance.markerClick(fileData, options);
    } else {
      const playerIndex = this.playerDatas.findIndex(v => v.cid === fileData.cid && v.isLiving === false);
      this.listViewRef.current.playerHistoryVideo(options, playerIndex, fileData);
    }
  }

  render() {
    const { videoModule } = this.props;
    const { isSlide, mapMode, showLoopSetting, showGroup, currentGroup, groupModalKey, loopModalKey } = this.state;
    return (
      <Provider value={this.getModuleContext()}>
        <div className={"video-surveillance video-surveillance" + this.clsSuffix} ref={this.videoLayoutRef}>
          <div className={`left-tree ${isSlide ? "left-tree-slide" : ""}`}>
            <div className="slide-layout-left-tree">
              <ResourceTreeView 
                hideLayout={!isSlide} 
                goPage={this.goPage} 
                onSelectDevice={this.onSelectDevice}
                editGroupDevice={this.editGroupDevice}
                handleHistoryVideo={this.handleHistoryVideo}
              />
            </div>
            <span className="slider-btn" onClick={this.slideAction}>
              <IconFont type={isSlide ? "icon-S_Arrow_SmallLeft" : "icon-S_Arrow_SmallRight"} theme="outlined" />
            </span>
          </div>
          <div className="right-content">
            <Button type="primary" className="change-mode-btn orange-btn" onClick={this.changeModeBtn}>
              <IconFont type={mapMode ? "icon-S_Photo_ListMap" : "icon-S_Bar_Map"} />
              {mapMode ? "分屏模式" : "地图模式"}
            </Button>
            <MoveableTimeChoise ref={this.timeChoiseRef} onOk={this.submitHistoryTime} />
            {mapMode ? (
              <MapLayout
                mapConfig={this.mapConfig}
                toggleTimeChoiseContent={this.toggleTimeChoiseContent}
                closeVideo={this.clearSelectDevice}
                refProps={this.mapViewRef}
                handleHistoryVideo={this.handleHistoryVideo}
                queryHistoryAddress={this.queryHistoryAddress}
                queryRealTimeAddressMulti={this.queryRealTimeAddressMulti}
                markerMouseover={this.markerMouseover}
                goPage={this.goPage}
                getLinkSelectList={this.getLinkSelectList}
                videoLayoutRef={this.videoLayoutRef}
                onSelectDevice={this.onSelectDevice}
                onFrontEndSeek={this.onFrontEndSeek}
              />
            ) : (
              <ListView
                closeVideo={this.clearSelectDevice}
                closeSingleVideo={this.closeSingleVideo}
                videoModule={videoModule}
                ref={this.listViewRef}
                selectSrceen={this.selectSrceen}
                currentScreen={videoModule.currentVideoScreen}
                updateTimeChoiseDeviceInfo={this.updateTimeChoiseDeviceInfo}
                onFrontEndSeek={this.onFrontEndSeek}
              />
            )}
          </div>
          {showLoopSetting && (
            <VideoLoopSetting
              playerDatas={this.playerDatas}
              startVideoLoop={this.startVideoLoop}
              closeLoopSettingLayout={this.closeLoopSettingLayout}
              showLoop={showLoopSetting}
              key={loopModalKey}
              currentScreen={videoModule.currentVideoScreen}
              loopListSize={this.loopList.length}
            />
          )}
          {showGroup && <GroupModal onOk={this.addOrEditGroup} visible={showGroup} onCancel={this.hideGroupModal} key={groupModalKey} group={currentGroup} />}
        </div>
      </Provider>
    );
  }
}
export default VideoSurveillance;
