import React from "react";
import { observer } from "mobx-react";
import SearchForm from "./SearchForm";
import CollectionList from "./CollectionList";
import * as _ from "lodash";
import DeviceContent from "./DeviceContent";
import GroupAction from "./GroupAction";

import "../style/resouce-search.less";

@Decorator.businessProvider("deviceGroup")
@Decorator.businessObserver({ deviceGroup: ["list"] })
class ResourceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.deviceContentRef = React.createRef();
    this.state = {
      isResource: true,
      form: {
        keyword: null,
        status: undefined,
        type: undefined,
        orgId: null,
        placeId: null
      }
    };
  }
  changeForm = form => {
    const formOld = this.state.form;
    form = Object.assign({}, formOld, form);
    this.setState({ form });
    // 强制刷新设备列表
    this.deviceContentRef.current.wrappedInstance.current.updateListForce();
  };

  addGroup = () => {
    const { showGroupModal } = this.props;
    showGroupModal();
  };

  changeOrg = orgId => {
    const { form } = this.state;
    this.setState({ form: Object.assign({}, form, { orgId }) });
  };

  changePlace = placeId => {
    const { form } = this.state;
    this.setState({ form: Object.assign({}, form, { placeId }) });
  };

  render() {
    const { isResource, form } = this.state;
    const { deviceGroup, hideLayout, onSelectDevice, goPage, editGroupDevice, handleHistoryVideo } = this.props;
    const collectionList = hideLayout ? [] : deviceGroup.list;
    return (
      <div className="resource-search">
        <div className="tab-layout">
          <div
            onClick={() => !isResource && this.setState({ isResource: true })}
            className={`tab-item ${isResource ? "tab-item-active" : ""}`}
          >
            全部资源
          </div>
          <div
            onClick={() => isResource && this.setState({ isResource: false })}
            className={`tab-item ${!isResource ? "tab-item-active" : ""}`}
          >
            我的分组
          </div>
        </div>
        {isResource ? (
          <div className="resource-part">
            <SearchForm params={form} changeForm={this.changeForm} />
            <DeviceContent
              onSelectDevice={onSelectDevice}
              changeOrg={this.changeOrg}
              changePlace={this.changePlace}
              hideLayout={hideLayout}
              params={{ ...form, resourceMode: this.resourceMode }}
              keyword={form.keyword}
              collectionList={collectionList}
              goPage={goPage}
              editGroupDevice={editGroupDevice}
              handleHistoryVideo={handleHistoryVideo}
              className="org-tree-with-device"
              ref={this.deviceContentRef}
            />
          </div>
        ) : (
          <div className="collection-part">
            <GroupAction collectionList={collectionList} />
            <CollectionList collectionList={collectionList} />
          </div>
        )}
      </div>
    );
  }
}
export default ResourceSearch;
