import React from "react";
import ResourceTopView from "./components/ResourceTopView";
import ResourceSearch from "./components/ResourceSearch";
import "./style/index.less";


class ResourceTreeView extends React.Component {
  render() {
    const { 
      goPage,
      onSelectDevice,
      hideLayout,
      editGroupDevice,
      handleHistoryVideo,
    } = this.props;
    return (
      <div className="resource-tree-layout">
        <ResourceTopView />
        <ResourceSearch
          goPage={goPage}
          onSelectDevice={onSelectDevice}
          hideLayout={hideLayout}
          editGroupDevice={editGroupDevice}
          handleHistoryVideo={handleHistoryVideo}
        />
      </div>
    );
  }
}
export default ResourceTreeView;
