import React from "react";
import "./index.less";
const IconFont = Loader.loadBaseComponent("IconFont");

export default class InlineSelect extends React.Component {
  constructor(props) {
    super(props);
    const { value = [] } = this.props;
    this.state = {
      visible: false,
      value
    };
  }
  changeVisible = () => {
    this.setState({ visible: !this.state.visible });
  };
  selectChange = (item, e) => {
    Utils.stopPropagation(e);
    const { value } = this.state;
    const index = value.indexOf(item.value);
    if (index > -1) {
      value.splice(index, 1);
    } else {
      value.push(item.value);
    }
    this.setState({ value, visible: false });
    this.props.onChange && this.props.onChange(value);
  };
  render() {
    const { visible, value } = this.state;
    const { className, placeholder, style, options } = this.props;
    return (
      <div className={`custom-ant-select ant-select  ${className}`} style={style} ref={this.domRef}>
        <div className="ant-select-selection ant-select-selection--multiple">
          <div className="ant-select-selection__rendered" onClick={this.changeVisible}>
            <div style={{ display: value.length > 0 ? "none" : "block" }} className="ant-select-selection__placeholder">
              {placeholder}
            </div>
            <ul>
              {value.map(v => (
                <li className="ant-select-selection__choice">
                  <div className="ant-select-selection__choice__content">{v}</div>
                  <span className="ant-select-selection__choice__remove" onClick={() => this.removeItem(v)}>
                    <IconFont type="icon-S_Edit_Yes" />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {visible && (
          <div className="custon-select-options">
            <ul>
              {options.map(v => (
                <li
                  className={value.indexOf(v.value) > -1 ? "custom-select-active" : ""}
                  key={v.value}
                  onClick={e => this.selectChange(v, e)}
                >
                  {v.label}
                  <IconFont type="icon-S_Edit_Yes" />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
