import React from "react";
import { observer } from "mobx-react";
import Video from "./video";

@Decorator.withEntryLog()
@Decorator.businessProvider("deviceGroup", "videoModule")
@observer
class VideoSurveillance extends React.Component {
  render() {
    const { deviceGroup, videoModule } = this.props;
    return <Video obserserData={{ ...deviceGroup, ...videoModule }} deviceGroup={deviceGroup} videoModule={videoModule} />;
  }
}
export default VideoSurveillance;
