import React from "react";
import { Popover, Radio, Tooltip } from "antd";
import { videoContext } from "../../../moduleContext";
import { observer } from "mobx-react";

const OrgTree = Loader.loadBusinessComponent("OrgTree");
const PlaceTree = Loader.loadBaseComponent("PlaceTree");
const IconFont = Loader.loadBaseComponent("IconFont");

const RadioGroup = Radio.Group;

@videoContext
@Decorator.businessObserver({})
class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listModeVisible: false
    };
  }
  renderLoopBtn = (node, type) => {
    const { isMapMode, showLoopSettingLayout } = this.props;
    const hasLiving = !!BaseStore.menu.getInfoByName("realVideo");
    if (isMapMode || !hasLiving) {
      return null;
    }
    return (
      <span
        className="video-lx"
        title="视频轮巡"
        onClick={e => {
          Utils.stopPropagation(e);
          showLoopSettingLayout(node, false, type);
        }}
      >
        <IconFont type="icon-S_Edit_RoundPlay" />
      </span>
    );
  };

  setResourceMode = mode => {
    this.setListModeVisible();
    setTimeout(() => {
      this.props.setResourceMode(mode);
    }, 10);
  };

  setListModeVisible = () => {
    const { listModeVisible } = this.state;
    this.setState({
      listModeVisible: !listModeVisible
    });
  };
  render() {
    const { listModeVisible } = this.state;
    const {
      showOrgLayout,
      onSelectOrg,
      changeLayoutStatus,
      onSelectPlace,
      mode,
      selectOrg,
      activePlaceId,
      domPrefix,
      loopOrgInfo
    } = this.props;
    return (
      <div className="org-tree-part">
        <div className="title-part">
          <div className="title-part-left fl" onClick={() => changeLayoutStatus(false, !showOrgLayout)}>
            <IconFont type={showOrgLayout ? "icon-S_Arrow_SmallUp" : "icon-S_Arrow_SmallDown"} />
            {mode === "org" ? "组织机构" : "行政区划"}
          </div>
          <div className="title-part-right fr">
            {BSConfig.microService.place && (
              <Popover
                content={
                  <RadioGroup onChange={this.setResourceMode} value={mode}>
                    <Radio style={{ display: "block" }} value="org">
                      组织结构
                    </Radio>
                    <Radio style={{ display: "block" }} value="place">
                      行政区划
                    </Radio>
                  </RadioGroup>
                }
                visible={listModeVisible}
                placement="bottomRight"
                getPopupContainer={() => document.querySelector(`.list-mode-radio-${domPrefix}`)}
              >
                <Tooltip title="展示模式">
                  <IconFont
                    onClick={this.setListModeVisible}
                    className={`list-mode-radio list-mode-radio-${domPrefix}`}
                    type="icon-S_Photo_ListMode"
                  />
                </Tooltip>
              </Popover>
            )}
          </div>
        </div>
        {mode === "org" ? (
          <OrgTree
            showCount={true}
            className={!showOrgLayout ? "hide-tree" : ""}
            activeKey={[selectOrg]}
            onSelect={onSelectOrg}
            renderSuffix={node => this.renderLoopBtn(node, "org")}
            treeNodeClass={node => (node.id === loopOrgInfo.id ? "loop-org" : "")}
          />
        ) : (
          <PlaceTree
            showCount={true}
            className={!showOrgLayout ? "hide-tree" : ""}
            activeKey={[activePlaceId]}
            onSelect={onSelectPlace}
            renderSuffix={node => this.renderLoopBtn(node, "place")}
            treeNodeClass={node => (node.id === loopOrgInfo.id ? "loop-org" : "")}
          />
        )}
      </div>
    );
  }
}

export default Tree;
