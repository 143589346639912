import React from 'react'
import DragContent from './DragContent'
import { message, Button, Popover, Radio } from 'antd'
import * as _ from 'lodash'
import Tree from './Tree'
import InlineSelect from './InlineSelect'
import '../style/device-content.less'

const ListComponent = Loader.loadBusinessComponent('ListComponent')
const IconFont = Loader.loadBaseComponent('IconFont')
const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const danbing = Dict.getDict('db')
const qiuji = Dict.getDict('qj')
const ModalSetPointMap = Loader.loadBusinessComponent('ModalSetPointMap')

@Decorator.businessObserver({})
class CameraList extends React.Component {
  constructor(props) {
    super(props)
    this.canBeClick = false // 初始进入置为false,解决其他页面双击跳转出现的bug
    this.doubleClickTime = true // 当前页面双击触发两次的bug
    this.groupNames = []
    this.deviceExts = {}

    this.listRef = React.createRef()
    this.state = {
      showOrgLayout: true,
      showDeviceLayout: true,
      selectOrg: null,
      point: null,
      visible: false,
      currentId: null,
      isShowPointMap: false, // 设置地图点位弹框
      setPointItem: {}, // 当前点位
      mode: 'org', // 设备展示方式 org, place
      activePlaceId: null,
      listModeVisible: false,
      focusSelect: true
    }
  }

  domPrefix = Math.random()
    .toString()
    .split('.')[1]

  componentDidMount() {
    SocketEmitter.on(SocketEmitter.eventName.editDeviceInfo, this.updateListForceTimeout)
    SocketEmitter.on(SocketEmitter.eventName.deviceUpdate, this.cleanCache)
    setTimeout(() => {
      this.canBeClick = true
    }, 300)
  }

  cleanCache = () => {
    if (this.props.storeId === BaseStore.tab.currentId) {
      window.videoCameraMap = {}
      this.forceUpdate()
      this.updateListForceTimeout()
    }
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.editDeviceInfo, this.updateListForceTimeout)
    SocketEmitter.off(SocketEmitter.eventName.deviceUpdate, this.cleanCache)
    clearTimeout(this.clickTime)
  }

  updateListForceTimeout = () => {
    setTimeout(() => {
      this.updateListForce()
    }, 10)
  }

  /**
   * @desc 强制更新大列表
   * @param {*} deviceList
   */
  updateListForce = () => {
    this.listRef.current.forceUpdateGrid()
  }

  /**
   * @desc 显示和隐藏组织和设备部分布局
   */
  changeLayoutStatus = (isDevice, flag) => {
    if (isDevice) {
      this.setState({ showDeviceLayout: flag })
    } else {
      this.setState({ showOrgLayout: flag })
    }
  }

  /**
   * @desc 选中组织机构
   */
  onSelectOrg = item => {
    const { selectOrg } = this.state
    let id = null
    if (selectOrg !== item[0]) {
      id = item[0]
    }
    this.setState({ selectOrg: id, focusSelect: false }, () =>
      setTimeout(() => {
        this.setState({ focusSelect: true })
      }, 200)
    )
    this.props.changeOrg && this.props.changeOrg(id)
    // this.updateListForce();
  }

  /**
   * @desc 显示设备收藏信息
   */
  showDeviceGroup = (item, event) => {
    Utils.stopPropagation(event)
    if (!this.deviceExts[item.id]) {
      const { collectionList } = this.props
      this.groupNames = collectionList.filter(v => v.deviceList.find(v2 => v2.id === item.id)).map(v => v.groupName)
    }
    this.deviceExts = { [item.id]: !this.deviceExts[item.id] }
    this.updateListForce()
  }

  /**
   * @desc 缓存选中的分组
   */
  changeGroup = (selectGroup, item) => {
    this.groupNames = selectGroup
    // this.updateListForce();
  }

  /**
   * @desc 取消分组
   */
  cancelGroup = item => {
    this.groupNames = []
    this.deviceExts[item.id] = false
    this.updateListForce()
  }

  /**
   * @desc 提交设备分组信息
   */
  subGroup = item => {
    let isEmpty, arr
    if (this.groupNames.length === 0) {
      isEmpty = true
      arr = [
        {
          deviceKey: `${item.cid}/${item.deviceName}`
        }
      ]
    } else {
      arr = this.groupNames.map(name => ({
        groupName: name,
        deviceKey: `${item.cid}/${item.deviceName}`
      }))
    }
    this.props
      .editGroupDevice(arr, isEmpty)
      .then(() => {
        message.success('操作成功！')
      })
      .then(() => this.cancelGroup(item))
  }

  /**
   * @desc 计算当前render 设备列表中 各个设备状态的统计
   * @param {Array} deviceList
   */
  computedListStatus(deviceList) {
    let onlineList = [],
      offlineList = []
    for (let i = 0, l = deviceList.length; i < l; i++) {
      let item = deviceList[i]
      if (item.deviceStatus * 1 === 1) {
        onlineList.push(item)
      } else {
        offlineList.push(item)
      }
    }
    return {
      list: onlineList.concat(offlineList),
      onlineCount: onlineList.length,
      offlineCount: offlineList.length
    }
  }

  /**
   * @desc 跳转设备编辑
   */
  jumpDeviceEdit = (event, item) => {
    Utils.stopPropagation(event)
    const { goPage } = this.props
    goPage({
      moduleName: 'deviceManagement',
      data: { id: item.id }
    })
  }

  // 同步远程开流偏好数据
  getDevicePrefercence = () => {
    const { device, user } = BaseStore
    const userId = user.userInfo.id
    const storeKey = device.streamKVKey
    return Service.kvStore
      .getKvStore({ userId, storeKey })
      .then(storeValue => {
        const streamPreference = JSON.parse(storeValue.data.storeValue || '[]')
        return device.setStreamPreference(streamPreference)
      })
      .catch(() => false)
  }

  /**
   * @desc 处理新的开流偏好数据
   */
  handleStreamPreference = async (deviceId, shouldFlv) => {
    let streamPreference = await this.getDevicePrefercence()
    if (!streamPreference) {
      message.error('设置失败，请重试')
      return false
    }
    const index = streamPreference.indexOf(deviceId)
    if (shouldFlv && index === -1) {
      streamPreference.push(deviceId)
    }
    if (!shouldFlv && index !== -1) {
      streamPreference.splice(index, 1)
    }
    return streamPreference
  }
  /**
   * @desc 设置开流偏好
   */
  setStreamPreference = async (deviceItem, shouldFlv) => {
    const { device, user } = BaseStore
    const userId = user.userInfo.id
    const storeKey = device.streamKVKey
    const storeValue = await this.handleStreamPreference(deviceItem.cid, shouldFlv)
    if (storeValue) {
      Service.kvStore
        .setUserKvStore({
          userId,
          storeKey,
          storeValue
        })
        .then(result => {
          message.success('设置成功')
          device.setStreamPreference(storeValue)
          this.updateListForce()
        })
        .catch(() => {
          message.error('设置失败，请重试')
        })
    }
  }

  // 云录像处理
  handleHistoryVideo = (event, item) => {
    Utils.stopPropagation(event)
    item.isFrontEnd = false
    this.props.handleHistoryVideo(item)
  }
  // 前端录像处理
  handleFrontEndVideo = (event, item) => {
    Utils.stopPropagation(event)
    item.isFrontEnd = true
    this.props.handleHistoryVideo(item)
  }
  /**
   * @desc 设置地图点位
   */
  setMapPoint = (e, item) => {
    Utils.stopPropagation(e)
    // 新增代码，初次进入时通过point取到所属场所
    if (item.placeId) {
      BSConfig.microService.place &&
        Service.place.getPlacesByConditions({ placeIds: [item.placeId] }).then(res => {
          const areaName = res.data[0].areaName
          item.areaName = areaName
          this.setState({
            isShowPointMap: true,
            setPointItem: item
          })
        })
    } else {
      item.areaName = ''
      this.setState({
        isShowPointMap: true,
        setPointItem: item
      })
    }
  }

  /**
   * @desc 关闭设置点位弹框
   */
  cancelPointMap = () => {
    this.setState({
      isShowPointMap: false
    })
  }

  /**
   * @desc 更新点位回调
   */
  setPoint = () => {
    this.updateListForceTimeout()
    this.cancelPointMap()
  }

  // 资源展示方式
  setResourceMode = e => {
    const mode = e.target.value
    this.setState({ mode })
  }

  /**
   * @desc 选中行政区划
   */
  onSelectPlace = ids => {
    let { activePlaceId } = this.state
    activePlaceId = activePlaceId === ids[0] ? null : ids[0]
    this.setState({ activePlaceId, focusSelect: false }, () => {
      setTimeout(() => {
        this.setState({ focusSelect: true })
      }, 200)
    })
    this.props.changePlace && this.props.changePlace(activePlaceId)
    // this.updateListForce();
  }

  renderAction = item => {
    const streamPreference = BaseStore.device.streamPreference
    const isFlv = streamPreference.indexOf(item.cid) !== -1
    const hasLiving = !!BaseStore.menu.getInfoByName('realVideo')
    return (
      <>
        <div className={`group-item-${item.id}`} />
        <span className="device-item-tools">
          {item.hasStorage && (
            <AuthComponent actionName="historyVideo">
              <IconFont type="icon-L_Bar_Video" title="云录像" onClick={e => this.handleHistoryVideo(e, item)} />
            </AuthComponent>
          )}
          {item.hasFrontEnd && (
            <AuthComponent actionName="historyVideo">
              <IconFont type="icon-S_Edit_ClockStart" title="前端录像" onClick={e => this.handleFrontEndVideo(e, item)} />
            </AuthComponent>
          )}
          <Popover visible={this.deviceExts[item.id]} overlayClassName="group-popover-layout" placement="topRight" content={this.renderGroup(item)}>
            <IconFont type="icon-S_Photo_Keep" title="添加到分组" onClick={event => this.showDeviceGroup(item, event)} />
          </Popover>
          {item.deviceType != danbing.value && (
            <React.Fragment>
              <AuthComponent actionName="deviceLocation">
                <IconFont type="icon-S_Bar_SetCamera" title="点位设置" onClick={e => this.setMapPoint(e, item)} />
              </AuthComponent>
              <AuthComponent actionName="deviceManagement">
                <IconFont type="icon-S_Edit_Edit" onClick={e => this.jumpDeviceEdit(e, item)} title="设备编辑" />
              </AuthComponent>
            </React.Fragment>
          )}
          {hasLiving && item.deviceType != danbing.value && item.deviceType != qiuji.value && item.hasStorage && (
            <Popover
              content={
                <div>
                  <Radio checked={isFlv} onChange={() => this.setStreamPreference(item, true)}>
                    实时优先
                  </Radio>
                  <Radio checked={!isFlv} onChange={() => this.setStreamPreference(item, false)}>
                    流畅优先
                  </Radio>
                </div>
              }
              trigger="click"
              placement="topRight"
              getPopupContainer={() => document.querySelector(`.device-stream-tools-${item.id}-${this.domPrefix}`)}
            >
              <IconFont className={`device-stream-tools device-stream-tools-${item.id}-${this.domPrefix}`} type="icon-S_Bar_Set" title="播放设置" />
            </Popover>
          )}
        </span>
      </>
    )
  }
  renderGroup = item => {
    const { collectionList } = this.props
    return (
      <div className="device-to-group">
        <div className="group-select-title">{item.deviceName}</div>
        <div style={{ position: 'relative' }} className={`group-select-${item.id} group-select-${item.id}-${this.domPrefix}`} />
        <InlineSelect
          placeholder="请选择分组"
          key={this.deviceExts[item.id] ? `${item.id}-${this.deviceExts[item.id]}` : 'group-select-com'}
          mode="multiple"
          onChange={v => this.changeGroup(v, item)}
          className="group-select-com"
          value={this.groupNames}
          options={collectionList.map(v => ({ label: v.groupName, value: v.groupName }))}
        />
        <div className="group-btn">
          <Button size="small" onClick={() => this.cancelGroup(item)}>
            取消
          </Button>
          <Button size="small" type="primary" onClick={() => this.subGroup(item)}>
            确定
          </Button>
        </div>
      </div>
    )
  }

  /**
   * @desc 单击单个设备播放视频
   */
  onSelectDevice = item => {
    if (this.canBeClick) {
      // 初始进入是，不能点击（解决v3.0.1双击没有经纬度的设备的bug)
      // 当前页面双击，节流阀
      if (!this.doubleClickTime) {
        return
      }
      this.doubleClickTime = false
      clearTimeout(this.clickTime)
      this.clickTime = setTimeout(() => {
        this.doubleClickTime = true
      }, 1000)
      this.props.onSelectDevice(item)
    }
  }

  queryDevice(list, params) {
    const isOrgModel = this.state.mode === 'org'
    let { keyword, status, type, orgId, placeId } = params

    keyword = _.trim(keyword)
    if (!keyword && !status && !type && !`${this.state.mode}Id`) {
      return list
    }
    const cacheKey = `${keyword}-${status}-${type}-${isOrgModel ? orgId : placeId}`
    if (!window.videoCameraMap) {
      window.videoCameraMap = {}
    }
    if (window.videoCameraMap[cacheKey]) {
      return window.videoCameraMap[cacheKey]
    }

    let orgIds = (orgId ? BaseStore.organization.queryOrgIdsForParentOrgId(orgId) : null) || []
    let placeIds = (placeId ? BaseStore.place.queryPlaceIdsForParentId(placeId) : null) || []

    let newList = list.filter(item => {
      let flags = Array(4).fill(false)
      if (keyword) {
        // 箱体号
        flags[0] = (item.deviceName && item.deviceName.indexOf(keyword) > -1) || item.sn === keyword || item.cid === keyword || item.boxNum == keyword
      } else {
        flags[0] = true
      }

      if (status && status !== '-1') {
        if (status * 1 === 0) {
          flags[1] = item.deviceStatus * 1 === 0
        } else {
          flags[1] = item.deviceStatus * 1 === 1
        }
      } else {
        flags[1] = true
      }

      if (type && type !== '-1') {
        flags[2] = item.deviceType * 1 === type * 1
      } else {
        flags[2] = true
      }

      if (isOrgModel) {
        if (orgId) {
          flags[3] = _.intersection(item.organizationIds, orgIds).length > 0
        } else {
          flags[3] = true
        }
      } else {
        if (placeId) {
          flags[3] = placeIds.indexOf(item.placeId) > -1
        } else {
          flags[3] = true
        }
      }
      return flags.filter(v => !v).length === 0
    })

    window.videoCameraMap[cacheKey] = newList
    // console.log(newList);
    return newList
  }

  render() {
    const { showOrgLayout, showDeviceLayout, selectOrg, isShowPointMap, setPointItem, mode, activePlaceId, focusSelect } = this.state
    const { selectDevice, keyword, params, hideLayout } = this.props
    console.time('computed-device')
    const deviceList = hideLayout || !focusSelect ? [] : this.queryDevice(this.props.deviceList, params)
    const result = this.computedListStatus(deviceList)
    console.timeEnd('computed-device')
    return (
      <div className="org-tree-with-device-layer">
        <Tree
          onSelectOrg={this.onSelectOrg}
          activePlaceId={activePlaceId}
          onSelectDevice={this.onSelectDevice}
          onSelectPlace={this.onSelectPlace}
          selectOrg={selectOrg}
          domPrefix={this.domPrefix}
          setResourceMode={this.setResourceMode}
          changeLayoutStatus={this.changeLayoutStatus}
          showOrgLayout={showOrgLayout}
          mode={mode}
        />
        <DragContent
          className={`${!showOrgLayout ? 'no-darg-height' : ''} ${!showDeviceLayout ? 'hide-darg-height' : ''}`}
          disabled={!showOrgLayout || !showDeviceLayout}
        >
          <div className="device-list-part" ref={this.deviceListRef}>
            <div className="title-part" onClick={() => this.changeLayoutStatus(true, !showDeviceLayout)}>
              <IconFont type={showDeviceLayout ? 'icon-S_Arrow_SmallUp' : 'icon-S_Arrow_SmallDown'} />
              摄像机列表
              <span className="count-part">
                <span className="count-part-on">{result.onlineCount}</span>
                <span className="count-part-off">{result.offlineCount}</span>
              </span>
            </div>
            <div className={`device-list-content ${!showDeviceLayout ? 'hide' : ''}`}>
              <ListComponent
                ref={this.listRef}
                listData={result.list}
                keyword={keyword}
                renderSuffix={this.renderAction}
                selectItemClass="active"
                onClick={this.onSelectDevice}
                selectList={selectDevice}
              />
            </div>
          </div>
        </DragContent>
        {/* 设置点位model */}
        {isShowPointMap && (
          <ModalSetPointMap
            className="set-point-modal"
            title="点位设置"
            visible={isShowPointMap}
            showSearch={true}
            onCancel={this.cancelPointMap}
            onOk={this.setPoint}
            width="50%"
            height="70%"
            destoryOnClose
            point={setPointItem}
            showPlaceModal={true}
          />
        )}
      </div>
    )
  }
}
export default CameraList
