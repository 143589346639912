import React from "react";
import LinkTools from "./LinkTools";
import MapView from "../view/map";
const ClusterMap = Loader.loadBusinessComponent("MapComponent", "ClusterMap");

@Decorator.businessObserver({})
class MapViewComponent extends React.Component {
  componentDidMount() {
    SocketEmitter.on(SocketEmitter.eventName.deviceUpdate, this.deviceUpdate);
  }
  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.deviceUpdate, this.deviceUpdate);
  }
  onInit = clusterLayer => {
    this.clusterLayer = clusterLayer;
  };
  deviceUpdate = async options => {
    if (!this.clusterLayer || this.props.storeId !== BaseStore.tab.currentId) {
      return;
    }
    console.time("update-markers");
    let addList = [];
    let updateList = [];
    let deleteList = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].type === "add") {
        addList.push(options[i].data);
      }
      if (options[i].type === "update") {
        updateList.push(options[i].data);
      }
      if (options[i].type === "delete") {
        deleteList.push(options[i].data);
      }
    }
    if (addList.length > 0 || updateList.length > 0) {
      this.clusterLayer.addOrUpdateMarkers([].concat(addList, updateList));
    }
    if (deleteList.length > 0) {
      this.clusterLayer.removeMarkers([].concat(deleteList));
    }
    console.timeEnd("update-markers");
  };
  render() {
    const {
      deviceList,
      mapConfig,
      goPage,
      markerMouseover,
      onSelectDevice,
      videoLayoutDom,
      queryRealTimeAddressMulti,
      toggleTimeChoiseContent,
      refProps,
      closeVideo,
      handleHistoryVideo,
      queryHistoryAddress,
      getLinkSelectList,
      onFrontEndSeek
    } = this.props;
    return (
      <ClusterMap
        // hasLiving={hasLiving}
        mapConfig={mapConfig}
        toggleTimeChoiseContent={toggleTimeChoiseContent}
        hasRectSearch={true}
        closeVideo={closeVideo}
        onInit={this.onInit}
        points={deviceList}
        ref={refProps}
        handleHistoryVideo={handleHistoryVideo}
        queryHistoryAddress={queryHistoryAddress}
        queryRealTimeAddressMulti={queryRealTimeAddressMulti}
        markerMouseover={markerMouseover}
        goPage={goPage}
        noAutoUpdate={true}
        onFrontEndSeek={onFrontEndSeek}
        hoverContent={
          <LinkTools
            className="map-icon-content-tools"
            goPage={goPage}
            excludeLink={["video"]}
            placement="bottom"
            getSelectList={getLinkSelectList}
          />
        }
      >
        <MapView goPage={goPage} onSelectDevice={onSelectDevice} ProcessRef={videoLayoutDom} />
      </ClusterMap>
    );
  }
}

export default MapViewComponent;
