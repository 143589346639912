(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("moment"), require("mobxReact"), require("_"), require("ReactRouterDOM"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("videoSurveillance", ["React", "antd", "moment", "mobxReact", "_", "ReactRouterDOM", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["videoSurveillance"] = factory(require("React"), require("antd"), require("moment"), require("mobxReact"), require("_"), require("ReactRouterDOM"), require("mobx"));
	else
		root["videoSurveillance"] = factory(root["React"], root["antd"], root["moment"], root["mobxReact"], root["_"], root["ReactRouterDOM"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 