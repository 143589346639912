import React from "react";
import { observer, inject } from "mobx-react";
import CameraList from "./CameraList";

@inject("device")
@observer
class DeviceContent extends React.Component {
  render() {
    const { forwardRef, device, ...props } = this.props;
    return <CameraList ref={forwardRef} {...props} deviceList={device.cameraList} />;
  }
}
export default React.forwardRef(
  (props, ref) => <DeviceContent {...props} forwardRef={ref}/>
);
